import { InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { acquireTokenRedirectWrapped, b2cPolicies } from "../authConfig";
import { DropdownMenuItem } from "./ui/dropdown-menu";

export const ResetPasswordLink = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance } = useMsal();

  const handleResetPassword = () => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      acquireTokenRedirectWrapped(instance, b2cPolicies.authorities.resetPassword);
    }
  };

  return (
    <DropdownMenuItem>
      <a
        href="#"
        className="flex items-center gap-2"
        onClick={handleResetPassword}
      >
        Reset Password
      </a>
    </DropdownMenuItem>
  );
};
