import { Dayjs } from 'dayjs';

export type Category = {
  categoryId: number;
  name: string;
};

export type Recipe = {
  recipeId: number;
  name: string;
  categoryId: number;
  ingredients?: string;
  procedure?: string;
  source?: string;
  isPrivate?: boolean;
  photos?: Photo[];
  steps?: RecipeStep[];
  createdByUserId: number;
  createdAt: Dayjs;
  modifiedAt: Dayjs;
};

export type Photo = {
  photoId: number;
  userId?: number;
  position: number;
  reference: string;
  contentType?: string;
};

export type RecipeStep = {
  recipeStepId: number;
  name?: string;
  description?: string;
  ingredients: Ingredient[];
  procedure?: string;
};

export type Ingredient = {
  ingredientId: number;
  amount?: number;
  unitId?: number;
  modifier?: string;
  foodId: number;
  notes?: string;
  originalText?: string;
};

export type Food = {
  foodId?: number;
  name?: string;
  pluralName?: string;
  description?: string;
  preferredUnitId?: number;
};

export type Unit = {
  unitId?: number;
  name?: string;
  pluralName?: string;
  description?: string;
};

export type RecipeDraft = {
  recipeDraftId: number;
  sourceUrl: string;
  systemPrompt?: string;
  userPrompt?: string;
  responseJson?: string;
  name?: string;
  ingredients?: string;
  procedure?: string;
  source?: string;
  metadata?: string;
  categoryId?: number;
  createdAt: Dayjs;
  createdByUserId: number;
};

export type SimpleUser = {
  userId: number;
  firstName: string;
  lastName: string;
  displayName: string;
};

export type Profile = {
  userId: number;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  roles: string[];
  favoriteRecipeIds: number[];
  recentRecipes: RecentRecipe[];
}

export type RecentRecipe = {
  recipeId: number;
  recentAt: Dayjs;
}

export const AnonymousProfile  = {
  favoriteRecipeIds: [] as number[],
  recentRecipes: [] as RecentRecipe[],
} as Profile;