import { PageTitle } from '@/components/PageTitle';
import { RecipeTable, SortField } from '@/components/RecipeTable';
import { buildCategory } from '@/models/categories.api';
import { categoriesQueryOptions, meQueryOptions, recipeDataLoader, recipesQueryOptions, usersQueryOptions } from '@/models/categories.queryOptions';
import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const idSchema = z.object({ categoryId: z.coerce.number().int().positive() });


export const Route = createFileRoute('/categories/$categoryId/')({
  parseParams: (params) => idSchema.parse(params),
  stringifyParams: (params) => ({ categoryId: String(params.categoryId) }),
  loader: ({ context}) => recipeDataLoader(context),
  component: CategoriesPage,
});


function CategoriesPage() {
  const { categoryId } = Route.useParams();

  const { category } = useSuspenseQueries({
    queries: [recipesQueryOptions, categoriesQueryOptions, usersQueryOptions, meQueryOptions],
    combine: ([recipesQuery, categoriesQuery, usersQuery, meQuery]) => {
      const category = categoriesQuery.data.find((c) => c.categoryId === +categoryId);
      if (!category) {
        return { category: null, isPending: true };
      }
      return {
        category: buildCategory(category, recipesQuery.data, usersQuery.data, meQuery.data),
        isPending: recipesQuery.isPending || categoriesQuery.isPending || usersQuery.isPending,
      };
    },
  });

  return (
    <div className="container mt-1 md:mt-3 px-2 pb-3">
      <PageTitle>{category?.name} - Opskrifter</PageTitle>

      <RecipeTable recipes={category?.recipes || []} showHeader={true} showCategory={false} sortField={SortField.Name} />
    </div>
  );
}
