import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { DropdownMenuItem } from "./ui/dropdown-menu";

export const SignInLink = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  return (
    <>
      <DropdownMenuItem>
        <a
          href="#"
          className="flex items-center gap-2"
          onClick={() => handleLogin("popup")}
        >
          Sign in using Popup
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <a
          href="#"
          className="flex items-center gap-2"
          onClick={() => handleLogin("redirect")}
        >
          Sign in using Redirect
        </a>
      </DropdownMenuItem>
    </>
  );
};
