/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SettingsImport } from './routes/settings'
import { Route as LogoutImport } from './routes/logout'
import { Route as IndexImport } from './routes/index'
import { Route as RecipesIndexImport } from './routes/recipes/index'
import { Route as RecipeDraftsIndexImport } from './routes/recipe-drafts/index'
import { Route as RecentsIndexImport } from './routes/recents/index'
import { Route as FavoritesIndexImport } from './routes/favorites/index'
import { Route as RecipeDraftsRecipeDraftIdImport } from './routes/recipe-drafts/$recipeDraftId'
import { Route as RecipesRecipeIdIndexImport } from './routes/recipes/$recipeId/index'
import { Route as CategoriesCategoryIdIndexImport } from './routes/categories/$categoryId/index'
import { Route as RecipesRecipeIdPhotoImport } from './routes/recipes/$recipeId/photo'

// Create Virtual Routes

const AboutLazyImport = createFileRoute('/about')()
const SettingsCategoriesLazyImport = createFileRoute('/settings/categories')()
const RecipesNewLazyImport = createFileRoute('/recipes/new')()
const RecipeDraftsNewLazyImport = createFileRoute('/recipe-drafts/new')()
const RecipesRecipeIdPhotosLazyImport = createFileRoute(
  '/recipes/$recipeId/photos',
)()
const RecipesRecipeIdEditLazyImport = createFileRoute(
  '/recipes/$recipeId/edit',
)()

// Create/Update Routes

const AboutLazyRoute = AboutLazyImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/about.lazy').then((d) => d.Route))

const SettingsRoute = SettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any)

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const RecipesIndexRoute = RecipesIndexImport.update({
  id: '/recipes/',
  path: '/recipes/',
  getParentRoute: () => rootRoute,
} as any)

const RecipeDraftsIndexRoute = RecipeDraftsIndexImport.update({
  id: '/recipe-drafts/',
  path: '/recipe-drafts/',
  getParentRoute: () => rootRoute,
} as any)

const RecentsIndexRoute = RecentsIndexImport.update({
  id: '/recents/',
  path: '/recents/',
  getParentRoute: () => rootRoute,
} as any)

const FavoritesIndexRoute = FavoritesIndexImport.update({
  id: '/favorites/',
  path: '/favorites/',
  getParentRoute: () => rootRoute,
} as any)

const SettingsCategoriesLazyRoute = SettingsCategoriesLazyImport.update({
  id: '/categories',
  path: '/categories',
  getParentRoute: () => SettingsRoute,
} as any).lazy(() =>
  import('./routes/settings/categories.lazy').then((d) => d.Route),
)

const RecipesNewLazyRoute = RecipesNewLazyImport.update({
  id: '/recipes/new',
  path: '/recipes/new',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/recipes/new.lazy').then((d) => d.Route))

const RecipeDraftsNewLazyRoute = RecipeDraftsNewLazyImport.update({
  id: '/recipe-drafts/new',
  path: '/recipe-drafts/new',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/recipe-drafts/new.lazy').then((d) => d.Route),
)

const RecipeDraftsRecipeDraftIdRoute = RecipeDraftsRecipeDraftIdImport.update({
  id: '/recipe-drafts/$recipeDraftId',
  path: '/recipe-drafts/$recipeDraftId',
  getParentRoute: () => rootRoute,
} as any)

const RecipesRecipeIdIndexRoute = RecipesRecipeIdIndexImport.update({
  id: '/recipes/$recipeId/',
  path: '/recipes/$recipeId/',
  getParentRoute: () => rootRoute,
} as any)

const CategoriesCategoryIdIndexRoute = CategoriesCategoryIdIndexImport.update({
  id: '/categories/$categoryId/',
  path: '/categories/$categoryId/',
  getParentRoute: () => rootRoute,
} as any)

const RecipesRecipeIdPhotosLazyRoute = RecipesRecipeIdPhotosLazyImport.update({
  id: '/recipes/$recipeId/photos',
  path: '/recipes/$recipeId/photos',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/recipes/$recipeId/photos.lazy').then((d) => d.Route),
)

const RecipesRecipeIdEditLazyRoute = RecipesRecipeIdEditLazyImport.update({
  id: '/recipes/$recipeId/edit',
  path: '/recipes/$recipeId/edit',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/recipes/$recipeId/edit.lazy').then((d) => d.Route),
)

const RecipesRecipeIdPhotoRoute = RecipesRecipeIdPhotoImport.update({
  id: '/recipes/$recipeId/photo',
  path: '/recipes/$recipeId/photo',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsImport
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutLazyImport
      parentRoute: typeof rootRoute
    }
    '/recipe-drafts/$recipeDraftId': {
      id: '/recipe-drafts/$recipeDraftId'
      path: '/recipe-drafts/$recipeDraftId'
      fullPath: '/recipe-drafts/$recipeDraftId'
      preLoaderRoute: typeof RecipeDraftsRecipeDraftIdImport
      parentRoute: typeof rootRoute
    }
    '/recipe-drafts/new': {
      id: '/recipe-drafts/new'
      path: '/recipe-drafts/new'
      fullPath: '/recipe-drafts/new'
      preLoaderRoute: typeof RecipeDraftsNewLazyImport
      parentRoute: typeof rootRoute
    }
    '/recipes/new': {
      id: '/recipes/new'
      path: '/recipes/new'
      fullPath: '/recipes/new'
      preLoaderRoute: typeof RecipesNewLazyImport
      parentRoute: typeof rootRoute
    }
    '/settings/categories': {
      id: '/settings/categories'
      path: '/categories'
      fullPath: '/settings/categories'
      preLoaderRoute: typeof SettingsCategoriesLazyImport
      parentRoute: typeof SettingsImport
    }
    '/favorites/': {
      id: '/favorites/'
      path: '/favorites'
      fullPath: '/favorites'
      preLoaderRoute: typeof FavoritesIndexImport
      parentRoute: typeof rootRoute
    }
    '/recents/': {
      id: '/recents/'
      path: '/recents'
      fullPath: '/recents'
      preLoaderRoute: typeof RecentsIndexImport
      parentRoute: typeof rootRoute
    }
    '/recipe-drafts/': {
      id: '/recipe-drafts/'
      path: '/recipe-drafts'
      fullPath: '/recipe-drafts'
      preLoaderRoute: typeof RecipeDraftsIndexImport
      parentRoute: typeof rootRoute
    }
    '/recipes/': {
      id: '/recipes/'
      path: '/recipes'
      fullPath: '/recipes'
      preLoaderRoute: typeof RecipesIndexImport
      parentRoute: typeof rootRoute
    }
    '/recipes/$recipeId/photo': {
      id: '/recipes/$recipeId/photo'
      path: '/recipes/$recipeId/photo'
      fullPath: '/recipes/$recipeId/photo'
      preLoaderRoute: typeof RecipesRecipeIdPhotoImport
      parentRoute: typeof rootRoute
    }
    '/recipes/$recipeId/edit': {
      id: '/recipes/$recipeId/edit'
      path: '/recipes/$recipeId/edit'
      fullPath: '/recipes/$recipeId/edit'
      preLoaderRoute: typeof RecipesRecipeIdEditLazyImport
      parentRoute: typeof rootRoute
    }
    '/recipes/$recipeId/photos': {
      id: '/recipes/$recipeId/photos'
      path: '/recipes/$recipeId/photos'
      fullPath: '/recipes/$recipeId/photos'
      preLoaderRoute: typeof RecipesRecipeIdPhotosLazyImport
      parentRoute: typeof rootRoute
    }
    '/categories/$categoryId/': {
      id: '/categories/$categoryId/'
      path: '/categories/$categoryId'
      fullPath: '/categories/$categoryId'
      preLoaderRoute: typeof CategoriesCategoryIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/recipes/$recipeId/': {
      id: '/recipes/$recipeId/'
      path: '/recipes/$recipeId'
      fullPath: '/recipes/$recipeId'
      preLoaderRoute: typeof RecipesRecipeIdIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

interface SettingsRouteChildren {
  SettingsCategoriesLazyRoute: typeof SettingsCategoriesLazyRoute
}

const SettingsRouteChildren: SettingsRouteChildren = {
  SettingsCategoriesLazyRoute: SettingsCategoriesLazyRoute,
}

const SettingsRouteWithChildren = SettingsRoute._addFileChildren(
  SettingsRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/logout': typeof LogoutRoute
  '/settings': typeof SettingsRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/recipe-drafts/$recipeDraftId': typeof RecipeDraftsRecipeDraftIdRoute
  '/recipe-drafts/new': typeof RecipeDraftsNewLazyRoute
  '/recipes/new': typeof RecipesNewLazyRoute
  '/settings/categories': typeof SettingsCategoriesLazyRoute
  '/favorites': typeof FavoritesIndexRoute
  '/recents': typeof RecentsIndexRoute
  '/recipe-drafts': typeof RecipeDraftsIndexRoute
  '/recipes': typeof RecipesIndexRoute
  '/recipes/$recipeId/photo': typeof RecipesRecipeIdPhotoRoute
  '/recipes/$recipeId/edit': typeof RecipesRecipeIdEditLazyRoute
  '/recipes/$recipeId/photos': typeof RecipesRecipeIdPhotosLazyRoute
  '/categories/$categoryId': typeof CategoriesCategoryIdIndexRoute
  '/recipes/$recipeId': typeof RecipesRecipeIdIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/logout': typeof LogoutRoute
  '/settings': typeof SettingsRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/recipe-drafts/$recipeDraftId': typeof RecipeDraftsRecipeDraftIdRoute
  '/recipe-drafts/new': typeof RecipeDraftsNewLazyRoute
  '/recipes/new': typeof RecipesNewLazyRoute
  '/settings/categories': typeof SettingsCategoriesLazyRoute
  '/favorites': typeof FavoritesIndexRoute
  '/recents': typeof RecentsIndexRoute
  '/recipe-drafts': typeof RecipeDraftsIndexRoute
  '/recipes': typeof RecipesIndexRoute
  '/recipes/$recipeId/photo': typeof RecipesRecipeIdPhotoRoute
  '/recipes/$recipeId/edit': typeof RecipesRecipeIdEditLazyRoute
  '/recipes/$recipeId/photos': typeof RecipesRecipeIdPhotosLazyRoute
  '/categories/$categoryId': typeof CategoriesCategoryIdIndexRoute
  '/recipes/$recipeId': typeof RecipesRecipeIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/logout': typeof LogoutRoute
  '/settings': typeof SettingsRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/recipe-drafts/$recipeDraftId': typeof RecipeDraftsRecipeDraftIdRoute
  '/recipe-drafts/new': typeof RecipeDraftsNewLazyRoute
  '/recipes/new': typeof RecipesNewLazyRoute
  '/settings/categories': typeof SettingsCategoriesLazyRoute
  '/favorites/': typeof FavoritesIndexRoute
  '/recents/': typeof RecentsIndexRoute
  '/recipe-drafts/': typeof RecipeDraftsIndexRoute
  '/recipes/': typeof RecipesIndexRoute
  '/recipes/$recipeId/photo': typeof RecipesRecipeIdPhotoRoute
  '/recipes/$recipeId/edit': typeof RecipesRecipeIdEditLazyRoute
  '/recipes/$recipeId/photos': typeof RecipesRecipeIdPhotosLazyRoute
  '/categories/$categoryId/': typeof CategoriesCategoryIdIndexRoute
  '/recipes/$recipeId/': typeof RecipesRecipeIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/logout'
    | '/settings'
    | '/about'
    | '/recipe-drafts/$recipeDraftId'
    | '/recipe-drafts/new'
    | '/recipes/new'
    | '/settings/categories'
    | '/favorites'
    | '/recents'
    | '/recipe-drafts'
    | '/recipes'
    | '/recipes/$recipeId/photo'
    | '/recipes/$recipeId/edit'
    | '/recipes/$recipeId/photos'
    | '/categories/$categoryId'
    | '/recipes/$recipeId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/logout'
    | '/settings'
    | '/about'
    | '/recipe-drafts/$recipeDraftId'
    | '/recipe-drafts/new'
    | '/recipes/new'
    | '/settings/categories'
    | '/favorites'
    | '/recents'
    | '/recipe-drafts'
    | '/recipes'
    | '/recipes/$recipeId/photo'
    | '/recipes/$recipeId/edit'
    | '/recipes/$recipeId/photos'
    | '/categories/$categoryId'
    | '/recipes/$recipeId'
  id:
    | '__root__'
    | '/'
    | '/logout'
    | '/settings'
    | '/about'
    | '/recipe-drafts/$recipeDraftId'
    | '/recipe-drafts/new'
    | '/recipes/new'
    | '/settings/categories'
    | '/favorites/'
    | '/recents/'
    | '/recipe-drafts/'
    | '/recipes/'
    | '/recipes/$recipeId/photo'
    | '/recipes/$recipeId/edit'
    | '/recipes/$recipeId/photos'
    | '/categories/$categoryId/'
    | '/recipes/$recipeId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  LogoutRoute: typeof LogoutRoute
  SettingsRoute: typeof SettingsRouteWithChildren
  AboutLazyRoute: typeof AboutLazyRoute
  RecipeDraftsRecipeDraftIdRoute: typeof RecipeDraftsRecipeDraftIdRoute
  RecipeDraftsNewLazyRoute: typeof RecipeDraftsNewLazyRoute
  RecipesNewLazyRoute: typeof RecipesNewLazyRoute
  FavoritesIndexRoute: typeof FavoritesIndexRoute
  RecentsIndexRoute: typeof RecentsIndexRoute
  RecipeDraftsIndexRoute: typeof RecipeDraftsIndexRoute
  RecipesIndexRoute: typeof RecipesIndexRoute
  RecipesRecipeIdPhotoRoute: typeof RecipesRecipeIdPhotoRoute
  RecipesRecipeIdEditLazyRoute: typeof RecipesRecipeIdEditLazyRoute
  RecipesRecipeIdPhotosLazyRoute: typeof RecipesRecipeIdPhotosLazyRoute
  CategoriesCategoryIdIndexRoute: typeof CategoriesCategoryIdIndexRoute
  RecipesRecipeIdIndexRoute: typeof RecipesRecipeIdIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  LogoutRoute: LogoutRoute,
  SettingsRoute: SettingsRouteWithChildren,
  AboutLazyRoute: AboutLazyRoute,
  RecipeDraftsRecipeDraftIdRoute: RecipeDraftsRecipeDraftIdRoute,
  RecipeDraftsNewLazyRoute: RecipeDraftsNewLazyRoute,
  RecipesNewLazyRoute: RecipesNewLazyRoute,
  FavoritesIndexRoute: FavoritesIndexRoute,
  RecentsIndexRoute: RecentsIndexRoute,
  RecipeDraftsIndexRoute: RecipeDraftsIndexRoute,
  RecipesIndexRoute: RecipesIndexRoute,
  RecipesRecipeIdPhotoRoute: RecipesRecipeIdPhotoRoute,
  RecipesRecipeIdEditLazyRoute: RecipesRecipeIdEditLazyRoute,
  RecipesRecipeIdPhotosLazyRoute: RecipesRecipeIdPhotosLazyRoute,
  CategoriesCategoryIdIndexRoute: CategoriesCategoryIdIndexRoute,
  RecipesRecipeIdIndexRoute: RecipesRecipeIdIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/logout",
        "/settings",
        "/about",
        "/recipe-drafts/$recipeDraftId",
        "/recipe-drafts/new",
        "/recipes/new",
        "/favorites/",
        "/recents/",
        "/recipe-drafts/",
        "/recipes/",
        "/recipes/$recipeId/photo",
        "/recipes/$recipeId/edit",
        "/recipes/$recipeId/photos",
        "/categories/$categoryId/",
        "/recipes/$recipeId/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/settings": {
      "filePath": "settings.tsx",
      "children": [
        "/settings/categories"
      ]
    },
    "/about": {
      "filePath": "about.lazy.tsx"
    },
    "/recipe-drafts/$recipeDraftId": {
      "filePath": "recipe-drafts/$recipeDraftId.tsx"
    },
    "/recipe-drafts/new": {
      "filePath": "recipe-drafts/new.lazy.tsx"
    },
    "/recipes/new": {
      "filePath": "recipes/new.lazy.tsx"
    },
    "/settings/categories": {
      "filePath": "settings/categories.lazy.tsx",
      "parent": "/settings"
    },
    "/favorites/": {
      "filePath": "favorites/index.tsx"
    },
    "/recents/": {
      "filePath": "recents/index.tsx"
    },
    "/recipe-drafts/": {
      "filePath": "recipe-drafts/index.tsx"
    },
    "/recipes/": {
      "filePath": "recipes/index.tsx"
    },
    "/recipes/$recipeId/photo": {
      "filePath": "recipes/$recipeId/photo.tsx"
    },
    "/recipes/$recipeId/edit": {
      "filePath": "recipes/$recipeId/edit.lazy.tsx"
    },
    "/recipes/$recipeId/photos": {
      "filePath": "recipes/$recipeId/photos.lazy.tsx"
    },
    "/categories/$categoryId/": {
      "filePath": "categories/$categoryId/index.tsx"
    },
    "/recipes/$recipeId/": {
      "filePath": "recipes/$recipeId/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
