import { Category } from '@/models/category';
import { Link } from '@tanstack/react-router';

export const CategoryLink = (category: Category) => {
  return (
    <Link
      to={`/categories/$categoryId`}
      params={{ categoryId: category.categoryId }}
    >
      {category.name}
    </Link>
  );
};
