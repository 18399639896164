import { AxiosHeaders, ResponseType } from 'axios'
import { AnonymousProfile, Category, Food, Profile, Recipe, SimpleUser, Unit } from '@/models/category';
import { msalInstance } from "@/main";
import dayjs from 'dayjs';
import { axiosInstance, getHeadersWithAuthentication } from './axiosInstance';

export const fetchCategories = async () => {
  const headers = await getHeadersWithAuthentication(new AxiosHeaders(axiosInstance.defaults.headers), { requireAccount: false });
  const response = await axiosInstance
    .get(`/categories`, {
      headers: headers,
      responseType: 'json' as ResponseType,
    });

  return response.data as Category[];
};

export const fetchUnits = async () => {
  const headers = await getHeadersWithAuthentication(new AxiosHeaders(axiosInstance.defaults.headers), { requireAccount: false });
  const response = await axiosInstance
    .get(`/units`, {
      headers: headers,
      responseType: 'json' as ResponseType,
    });

  return response.data as Unit[];
};

export const fetchFoods = async () => {
  const headers = await getHeadersWithAuthentication(new AxiosHeaders(axiosInstance.defaults.headers), { requireAccount: false });
  const response = await axiosInstance
    .get(`/foods`, {
      headers: headers,
      responseType: 'json' as ResponseType,
    });

  return response.data as Food[];
};

export const fetchMe = async () => {
  if (!msalInstance.getActiveAccount()) {
    return Promise.resolve(AnonymousProfile);
  }

  const headers = await getHeadersWithAuthentication(new AxiosHeaders(axiosInstance.defaults.headers), { requireAccount: true });
  const response = await axiosInstance
    .get(`/me`, {
      headers: headers,
      responseType: 'json' as ResponseType,
    });

  const profile = {
    ...response.data,
    favoriteRecipeIds: response.data.favoriteRecipeIds || [],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recentRecipes: response.data.recentRecipes?.map((r: any) => ({ recipeId: r.recipeId, recentAt: dayjs(r.recentAt) })) || [],
  } as Profile
  console.log('profile', profile);
  return profile;
};

export const fetchUsers = async () => {
  const headers = await getHeadersWithAuthentication(new AxiosHeaders(axiosInstance.defaults.headers), { requireAccount: false });
  const response = await axiosInstance
    .get(`/users`, {
      headers: headers,
      responseType: 'json' as ResponseType,
    });
  return response.data as SimpleUser[];
};


export const buildRecipe = (recipe: Recipe, categories: Category[], users: SimpleUser[], profile?: Profile) => {
  const category = categories.find((c) => c.categoryId === recipe.categoryId);
  const author = users.find((u) => u.userId === recipe.createdByUserId);

  return {
    ...recipe,
    category: category || { categoryId: 0, name: 'Unknown' },
    author: author?.displayName || author?.firstName && `${author.firstName}` || 'Unknown',
    isFavorite: profile?.favoriteRecipeIds.includes(recipe.recipeId) || false,
  };
};

export const buildCategory = (category: Category, recipes: Recipe[], users: SimpleUser[], profile: Profile) => {
  return {
    ...category,
    recipes: recipes
      .filter((r) => r.categoryId === category.categoryId)
      .map((recipe) => buildRecipe(recipe, [category], users, profile)),
  };
}