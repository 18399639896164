import { PageTitle } from '@/components/PageTitle';
import { Button } from '@/components/ui/button';
import { recipeDraftsQueryOptions } from '@/models/categories.queryOptions';
import { useExtractRecipeDraftMutation } from '@/models/recipeDrafts.api';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { Link } from '@tanstack/react-router';
import { ExternalLink } from 'lucide-react';

export const Route = createFileRoute('/recipe-drafts/')({
  loader: ({ context }) =>
    context.queryClient.ensureQueryData(recipeDraftsQueryOptions),
  component: RecipeDraftsPage,
});

function RecipeDraftsPage() {
  const { data: recipeDrafts } = useSuspenseQuery(recipeDraftsQueryOptions);

  const extractRecipeM = useExtractRecipeDraftMutation();

  const extractRecipe = async (recipeDraftId: number) => {
    console.log('extractRecipe', recipeDraftId);

    const recipeDraft = await extractRecipeM.mutateAsync(recipeDraftId);
    console.log('extractRecipe', recipeDraft);
  };

  return (
    <div className='container mt-1 md:mt-3 px-2 pb-3'>
      <PageTitle>Opskriftlinks</PageTitle>

      <Button variant={'outline'} className='mb-3'>
        <Link to='/recipe-drafts/new'>Tilføj Opskriftlink</Link>
      </Button>

      <div className='flex flex-col gap-y-2'>
        {recipeDrafts?.map((recipeDraft) => (
          <div
            key={recipeDraft.recipeDraftId}
            className='border border-gray-100 p-2'
          >
            <div className='flex flex-row gap-x-2 items-center'>
              <Link
                to={'/recipe-drafts/$recipeDraftId'}
                params={{ recipeDraftId: recipeDraft.recipeDraftId }}
              >
                {recipeDraft.sourceUrl}
              </Link>
              <a href={recipeDraft.sourceUrl} target='_blank' rel='noreferrer'>
                <ExternalLink size={16} />
              </a>
            </div>
            <Button
              variant={'outline'}
              onClick={async () =>
                await extractRecipe(recipeDraft.recipeDraftId)
              }
            >
              Process
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
