import { FavoriteToggle, IconSize } from '@/components/FavoriteToggle';
import { PageTitle } from '@/components/PageTitle';
import { PhotoStrip } from '@/components/PhotoStrip';
import { RecipeLinesComponent } from '@/components/RecipeLinesComponent';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { getStructuredRecipe } from '@/lib/recipeFormatter';
import { cn } from '@/lib/utils';
import {
  categoriesQueryOptions,
  detailedRecipeDataLoader,
  foodsQueryOptions,
  meQueryOptions,
  recipesQueryOptions,
  unitsQueryOptions,
} from '@/models/categories.queryOptions';
import { useUpdateFavoriteRecipeMutation } from '@/models/favoriteRecipes.api';
import { addRecentRecipe } from '@/models/recentRecipes.api';
import { useDeleteRecipeMutation } from '@/models/recipes.api';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { Edit, Images, Trash2 } from 'lucide-react';
import { useMemo, useState } from 'react';
import 'yet-another-react-lightbox/styles.css';
import { z } from 'zod';

const idSchema = z.object({
  recipeId: z.coerce.number().int().positive(),
});

export const Route = createFileRoute('/recipes/$recipeId/')({
  parseParams: (params) => idSchema.parse(params),
  stringifyParams: (params) => ({ recipeId: String(params.recipeId) }),
  loader: ({ context }) => detailedRecipeDataLoader(context),
  onEnter: (match) => {
    if (match.context && match.context.queryClient) {
      addRecentRecipe(+match.params.recipeId, match.context.queryClient);
    }
  },
  component: RecipePage,
});

function RecipePage() {
  const { recipeId } = Route.useParams();

  const navigate = useNavigate({ from: '/recipes/$recipeId' });

  const deleteRecipeM = useDeleteRecipeMutation();

  const [recipesQuery, categoriesQuery, meQuery, unitsQuery, foodsQuery] =
    useSuspenseQueries({
      queries: [
        recipesQueryOptions,
        categoriesQueryOptions,
        meQueryOptions,
        unitsQueryOptions,
        foodsQueryOptions,
      ],
    });
  const { data: recipes } = recipesQuery;
  const { data: categories } = categoriesQuery;
  const { data: units } = unitsQuery;
  const { data: foods } = foodsQuery;

  const [deleteOpen, setDeleteOpen] = useState(false);

  const toggleFavoriteRecipeM = useUpdateFavoriteRecipeMutation();

  const recipe = useMemo(() => {
    const recipe = recipes.find((r) => r.recipeId === +recipeId);

    return {
      ...recipe,
      category: categories?.find((c) => c.categoryId === recipe?.categoryId),
      isFavorite: meQuery.data?.favoriteRecipeIds.includes(+recipeId),
      structuredIngredients: getStructuredRecipe(recipe?.ingredients || ''),
      structuredProcedure: getStructuredRecipe(recipe?.procedure || ''),
      steps: recipe?.steps?.map((step) => ({
        ...step,
        ingredients: step.ingredients?.map((ingredient) => ({
          ...ingredient,
          unit: units?.find((u) => u.unitId === ingredient.unitId),
          food: foods?.find((f) => f.foodId === ingredient.foodId),
        })),
      })),
    };
  }, [
    recipes,
    categories,
    recipeId,
    meQuery?.data?.favoriteRecipeIds,
    units,
    foods,
  ]);

  if (!recipe) {
    return <div>Opskriften er ikke fundet</div>;
  }

  const setFavorite = (isFavorite: boolean) => {
    toggleFavoriteRecipeM.mutate({ recipeId: +recipeId, isFavorite });
  };

  const deleteRecipe = async () => {
    await deleteRecipeM.mutateAsync(+recipeId);
    navigate({ to: '/recipes' });
  };

  return (
    <div className='container mt-0 md:mt-3 px-2 pb-5'>
      <article>
        <div className='flex flex-row justify-between mb-1 items-center'>
          <PageTitle classNames='mb-0'>{recipe.name}</PageTitle>
          <div className='flex flex-row items-center gap-x-2'>
            <Link
              to={`/categories/$categoryId`}
              params={{ categoryId: recipe.categoryId ?? 0 }}
            >
              {recipe.category?.name}
            </Link>
            <FavoriteToggle
              isFavorite={recipe.isFavorite}
              onChange={(isFavorite) => setFavorite(isFavorite)}
              size={IconSize.Medium}
            />
          </div>
        </div>
        {recipe.photos && recipe.photos.length > 0 && (
          <div className='hidden md:block'>
            <PhotoStrip
              photos={recipe.photos.map((photo) => ({
                recipeId: +recipeId,
                photo,
              }))}
            />
          </div>
        )}
        {recipe.steps && recipe.steps.length > 0 && (
          <div className={cn('flex flex-col gap-y-2')}>
            {recipe.steps?.map((step) => (
              <div key={step.recipeStepId} className='mt-2'>
                <h3 className='text-xl font-sans dark:text-gray-400'>
                  {step.name}
                </h3>
                <div className={cn('flex flex-col gap-2', 'lg:flex-row')}>
                  {step.ingredients.length > 0 && (
                    <div className='flex-1  '>
                      <div className='border border-gray-200 dark:border-gray-600 bg-gray-50 dark:bg-gray-800 p-2 rounded-md'>
                        <table style={{ fontSize: '15px', lineHeight: '1.3' }}>
                          <tbody>
                            {step.ingredients?.map((ingredient) => (
                              <tr key={ingredient.ingredientId}>
                                <td className='text-right min-w-10'>
                                  {ingredient.amount}
                                </td>
                                <td className='ps-2'>
                                  {ingredient.unit?.name}
                                </td>
                                <td>{ingredient.modifier}</td>
                                <td>
                                  {ingredient.notes
                                    ? `${ingredient.food?.name}, ${ingredient.notes}`
                                    : ingredient.food?.name}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className='flex-1 '>
                    {step.procedure && (
                      <div className='border border-gray-200 dark:border-gray-600 bg-gray-50 dark:bg-gray-800 p-2 rounded-md'>
                        {step.procedure && (
                          <RecipeLinesComponent
                            lines={getStructuredRecipe(step.procedure)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {recipe.steps == null ||
          (recipe.steps.length == 0 && (
            <div
              className={cn(
                'flex flex-col gap-y-2',
                'lg:grid lg:grid-cols-2 lg:gap-4'
              )}
            >
              <div className='flex flex-col gap-2'>
                <h3 className='text-xl font-sans dark:text-gray-400'>
                  Ingredienser
                </h3>
                <div className='bg-gray-50 dark:bg-gray-800 py-1 px-2 border border-gray-200 dark:border-gray-600 rounded'>
                  <RecipeLinesComponent lines={recipe.structuredIngredients} />
                </div>
              </div>
              <div className='flex flex-col gap-2'>
                <h3 className='text-xl font-sans dark:text-gray-400'>
                  Fremgangsmåde
                </h3>
                <div className='bg-gray-50 dark:bg-gray-800 py-1 px-2 border border-gray-200 dark:border-gray-600 rounded'>
                  <RecipeLinesComponent lines={recipe.structuredProcedure} />
                </div>
              </div>
            </div>
          ))}

        <div className='flex flex-col gap-2 mt-3'>
          <h3 className='text-xl font-sans dark:text-gray-400'>Kilde</h3>
          {recipe.source}
        </div>
        <AuthenticatedTemplate>
          <div className='mt-3 flex justify-between print:hidden'>
            <div className='flex gap-x-2'>
              <Link
                to={'/recipes/$recipeId/edit'}
                params={{ recipeId: String(recipeId) }}
              >
                <Edit size={20} />
              </Link>
              <Link
                to={'/recipes/$recipeId/photos'}
                params={{ recipeId: String(recipeId) }}
              >
                <Images size={20} />
              </Link>
            </div>
            <AlertDialog open={deleteOpen} onOpenChange={setDeleteOpen}>
              <AlertDialogTrigger>
                <Trash2 color='darkred' size={20} />
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Er du helt sikker?</AlertDialogTitle>
                  <AlertDialogDescription>
                    Sletning af en opskrift kan ikke fortrydes. Dette vil
                    permanent slette din opskriften.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Annullér</AlertDialogCancel>
                  <AlertDialogAction onClick={deleteRecipe}>
                    Forsæt
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </AuthenticatedTemplate>
      </article>
    </div>
  );
}
