import { useMsal } from "@azure/msal-react";
import { DropdownMenuItem } from "./ui/dropdown-menu";

export const SignOutLink = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType: string) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  };

  return (
    <>
      <DropdownMenuItem>
        <a
          href="#"
          className="flex items-center gap-2"
          onClick={() => handleLogout("popup")}
        >
          Logout using Popup
        </a>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <a
          href="#"
          className="flex items-center gap-2"
          onClick={() => handleLogout("redirect")}
        >
          Logout using Redirect
        </a>
      </DropdownMenuItem>
    </>
  );
};
