import { Recipe } from '@/models/category';
import { Link } from '@tanstack/react-router';

export const RecipeLink = (recipe: Recipe) => {
  return (
    <Link className='text-apple-700' to={`/recipes/$recipeId`} params={{ recipeId: recipe.recipeId }}>
      {recipe.name}
    </Link>
  );
};
